import Navbar from "../components/Navbar";
import "../styles/Youth.css";
import nnaemeka from "../assets/images/youths/dr_nnaemeka_nweke.jpg";
import Footer from "../components/Footer";

const Youth = () => {
  return (
    <>
      <Navbar />
      <div className="youthWrapper">
            <div className="content">
            <h2>
                KEYNOTE SPEECH BY DR NNAEMEKA NWEKE-NATIONAL CHAIRMAN ENUGU USA
                YOUTH COMMITTEE
            </h2>
            <small>“Revitalizing Enugu State's Youth”</small>
            <p>
                <span>
                Ladies and gentlemen, distinguished guests, and fellow Members of
                EnuguUSA,
                </span>
                <span>
                Today marks a pivotal moment for our beloved Enugu State. In
                recent years, we've recognized a gap in the development and
                retention of our youth, and now, united in purpose, we stand
                poised to address this challenge head-on. As the chair of the
                Youth Committee, our unwavering commitment is succinctly captured
                in our mission: "To empower and uplift Enugu State's youth through
                community-driven initiatives, cultural preservation, and
                sustainable development, paving the way for a prosperous future.
                </span>
                <span>
                But how do we transform this vision into reality? To begin, we
                must establish a clear definition of who we consider as youth.
                It's my proposal that we encompass individuals from childhood up
                to the age of 40 within this category. This all-encompassing
                definition will not only clarify our scope but also underline the
                significance of investing in the financial resources necessary for
                effective youth retention. A substantial budget must be allocated,
                a budget that breathes life into innovative ideas and stands as a
                testament to our commitment to supporting the transformative
                impact our youth can and will have. After all, financial backing
                is the bedrock upon which our emotional aspirations must stand to
                truly flourish.
                </span>
                <span>
                The question arises: where do we find our youth, and how do we
                ignite their interest and involvement? To this end, we've embarked
                on a social media initiative, crafting an active presence on
                Instagram, Facebook, and Twitter. Through these platforms, we aim
                to communicate in real time, not only disseminating information
                but also cultivating an environment that beckons our lost youth
                back into our fold. Our aspiration is to ignite enthusiasm and
                rejuvenate what it means to be an integral part of Enugu State in
                the USA.
                </span>
                <span>
                The moment we stand in is undeniably a turning point. Parents and
                esteemed elders, I implore you to lend your unwavering focus to
                this cause. Our current commitment and investment will undoubtedly
                yield immeasurable dividends for the future we envision. The old
                adage, "Charity begins at home," holds true, and our home is none
                other than the United States of America. Let us seize the
                opportunity to harness the boundless talents and creativity that
                reside within our youth.
                </span>
                <span>
                In conclusion, I extend my heartfelt gratitude to each of you.
                This journey we embark upon today is one of profound importance,
                not just for ourselves, but for generations to come. Together, we
                can forge a future that's bright, inclusive, and emblematic of the
                spirit of Enugu State.
                </span>
            </p>
            </div>
            <img src={nnaemeka} alt="" />
      </div>

      <section className="meetYouths">
        <h2>More Information is Coming Soon</h2>
      </section>
      <Footer />
    </>
  );
};

export default Youth;
