import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/MedicalMission.css";

const MedicalMission2022 = () => {
  return (
    <>
      <Navbar />
      <section class="heroSection2022">
        <div class="MheroOverlay">
          <h2>2022 MEDICAL MISSION</h2>
          <p>THEME: Health is Gold</p>
        </div>
      </section>
      <div class="Mcontent">
        <p>
          In 2022, Enugu USA orchestrated a transformative medical outreach in
          Enugu State, Nigeria, delivering essential healthcare services to
          underserved communities. Through meticulous planning and collaboration
          with local stakeholders, the organization deployed mobile medical
          units staffed by dedicated professionals and volunteers, offering free
          general check-ups, dental and eye care, vaccinations, and treatment
          for chronic conditions like hypertension and diabetes. Beyond medical
          interventions, Enugu USA prioritized health education, empowering
          individuals with knowledge on nutrition, hygiene, and disease
          prevention. This holistic approach underscored the organization's
          commitment to enhancing healthcare accessibility and promoting
          well-being in Enugu State, leaving a profound and lasting impact on
          the communities served.
        </p>
      </div>
      <div class="galleryWrap">
        <div class="heading">
          <h2>Our Gallery</h2>
          <p>
            Explore the heartwarming moments captured during Enugu USA's
            transformative 2022 medical outreach, where dedicated professionals
            and volunteers brought free healthcare services and hope to
            underserved communities in Enugu State, Nigeria.
          </p>
        </div>
        <div class="imagesWrapper">
          <img
            src={require("../assets/images/2022MedicalMision/gallery (14).jpg")}
            alt=""
            class="pic1"
          />
          <img
            src={require("../assets/images/2022MedicalMision/gallery_2.jpg")}
            alt=""
            class="pic2"
          />
          <img
            src={require("../assets/images/2022MedicalMision/gallery_3.jpg")}
            alt=""
            class="pic3"
          />
          <img
            src={require("../assets/images/2022MedicalMision/gallery_4.jpg")}
            alt=""
            class="pic4"
          />
          <img
            src={require("../assets/images/2022MedicalMision/gallery_5.jpg")}
            alt=""
            class="pic5"
          />
          <img
            src={require("../assets/images/2022MedicalMision/gallery_6.jpg")}
            alt=""
            class="pic6"
          />
          <img
            src={require("../assets/images/2022MedicalMision/gallery_7.jpg")}
            alt=""
            class="pic7"
          />
          <img
            src={require("../assets/images/2022MedicalMision/gallery_9.jpg")}
            alt=""
            class="pic8"
          />
          <img
            src={require("../assets/images/2022MedicalMision/gallery_10.jpg")}
            alt=""
            class="pic9"
          />
        </div>
      </div>

      <section class="videoWrapper">
        <div class="section videoSet">
          <div class="leftMContent">
            <h2>Certificate of Completion</h2>
            <p>
              Enugu USA proudly celebrates the successful completion of our 2022
              medical outreach in Enugu State, Nigeria, where through dedication
              and collaboration, we provided essential healthcare services to
              underserved communities, leaving a lasting impact on the lives of
              those we served.
            </p>
          </div>
          <img
            src={require("../assets/images/2022MedicalMision/gallery_1.jpg")}
          ></img>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MedicalMission2022;
