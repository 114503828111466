import { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/Executives.css";
import CharlesAgwu from "../assets/images/President Emeritus/Chief Charles Agwu.jpg";
import ChrisChukwu from "../assets/images/President Emeritus/Chris Chukwu.jpg";
import JohnEgb from "../assets/images/President Emeritus/Hon. John Egb.jpg";
import ObyClara from "../assets/images/President Emeritus/Hon. Oby Clara Ada.jpg";
import IfeSinachi from "../assets/images/President Emeritus/Mrs. Ife Sinachi Ugwuonye-Okechukwu.jpg";
import ArinzeUzochukwu from "../assets/images/President Emeritus/Senior Prof.  G. Arinze Uzochukwu.jpg";
import SirEmma from "../assets/images/President Emeritus/Dr sir emma.jpg";
import Chukwuma from "../assets/images/President Emeritus/Chukwuma Calistus.jpg";
import AttyCelestine from "../assets/images/President Emeritus/AttyCelestine.jpg";

const PresidentEmemritus = () => {
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState(
    "../assets/images/Call center-cuate 1.png"
  );
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const showInfoModal = (name, image, title, desc) => {
    setName(name);
    setDesc(desc);
    setTitle(title);
    setModal(true);
    setImage(image);
    document.getElementById("description").innerHTML = desc;
  };

  return (
    <>
      <Navbar />
      <section class="executives">
        <div class="heading">
          <h2>
            Meet Our Past <span>President</span> Emeritus
          </h2>
          <p>
            These visionary leaders have played an integral role in shaping our
            organization and advancing our mission of unity, empowerment, and
            community development. With their leadership, Enugu USA has
            flourished, leaving a lasting legacy of impact and service.
          </p>
        </div>

        {/* EXECUTIVE CARDS  */}
        <div class="executivesCards">
          <div
            class="card"
            //    onClick="showInfoModal(`Mr Chris Chukwu`, `assets/images/President Emeritus/Chris Chukwu.jpg`, `ENUGU USA FOUNDING PRESIDENT 1998-2000`)"
          >
            <img src={ChrisChukwu} alt="" />
            <div class="content">
              <h3>Mr Chris Chukwu</h3>
              <p id="position">ENUGU USA FOUNDING PRESIDENT 1998-2000</p>
            </div>
          </div>

          <div
            class="card"
            //    onClick="showInfoModal(`Hon. John Egbo`, `assets/images/President Emeritus/Hon. John Egb.jpg`, `ENUGU USA PRESIDENT 2002-2004`)"
          >
            <img src={JohnEgb} alt="" />
            <div class="content">
              <h3>Hon. John Egbo</h3>
              <p id="position">ENUGU USA President 2002 - 2004</p>
            </div>
          </div>

          <div
            class="card"
            //    onClick="showInfoModal(`Chief Charles Agwu`, `assets/images/President Emeritus/Chief Charles Agwu.jpg`, `National President Emeritus 2004 - 2006`)"
          >
            <img src={CharlesAgwu} alt="" />
            <div class="content">
              <h3>Chief Charles Agwu</h3>
              <p id="position">National President Emeritus 2004 - 2006</p>
            </div>
          </div>

          <div
            class="card"
            onClick={() =>
              showInfoModal(
                `Hon. Oby Clara Ada Igbo, Nkiliejuafor/Ngalakwesili/madam P Original`,
                ObyClara,
                `1st Female ENUGU USA Madam President 2007 - 2008`,
                `<p><b>EnuguUSA 1st Female National Madam President Emeritus 2007 -2008</b> </p>
               <p>Madam P and her executive members started her régime with about  less than $1500, and with the collective efforts of most members, she was able to generate a large sum of money  during the 2008 National Convention in Miami Florida.  About $15.000  revenue went into the national purse.</p>
               <p>After her tenor, she was appointed the Special Adviser to several  National Presidents on Women Affairs Committee for some years. She also continued to support financially to medical mission and and other affairs of EnuguUSA until present.</p>
               <p><b>Former EnuguUSA Acting National President in the middle of 2007 till the end.</b> </p>
               <p>She contributed to overseeing the preparation /implementation  of activities for the 1st EnuguUSA international convention in Enugu state Nigeria. She prepared and participated in  award presentation to over 45 award numinees both from EnuguUSA and Enugu state at large.</p>
               <p><b>Former EnuguUSA National Vice President  and 1st Chairperson/Founder Women Affairs Committee 2006-2007.</b> </p>
               <p>The committee members were able to mobilize a great number of Enugu USA  women from different local chapters and created awareness for  them to start getting involved in contesting for postions / offices in the executive/ board leadership in EnuguUSA Association. They also are the pillars of event planning and in  fundraising.</br> Today, the current  national president  is a woman and the 3rd female national president. Kudos!</p>
               <p><b>Former Madam Chairperson EnuguUSA Cultural Affairs Committee  2004-2006.</b> </p>
               <p>Hon. Madam P Lady Oby Clara Igbo was appointed to be in charge of the supplies of the Okachamma women of EnuguUSA red/white uniform. In addition, She was made  the leader of the Okachamma women dance, which unités the whole of their women throughout the local chapters that are under the umbrella of EnuguUSA Association INC till present. </br> Before she took over, the money realized from the women's dance was shared among those who performed the dance. Since Madam P  assumed the position, the money realized started going   into the associations purse to date. </p>
               <p><b>Former Madam Treasurer EnuguUSA 2002-2004.</b> </p>
               <p>Madam T, as fondly called, handled the finances of the association with transparency and accountability. She  contributed immensely in jump starting  of the EnuguUSA medical mission . She also donated her Toyota Previa Van for use during the 2005 medical mission in Enugu state in Nigeria . She gave lots of her time and finances into traveling to be on the ground to support the mission for some years. </br> She further sponsored the T-shirts for EnuguUSA men and women football match competition for national  convention  in Chicago .</p>
               `
              )
            }
          >
            <img src={ObyClara} alt="" />
            <div class="content">
              <h3>
                Hon. Oby Clara Ada Igbo, Nkiliejuafor/Ngalakwesili/madam P
                Original
              </h3>
              <p id="position">
                1st Female ENUGU USA Madam President 2007 - 2008
              </p>
            </div>
          </div>


          <div
            class="card"
            onClick={() =>
              showInfoModal(
                `Senior Prof.  G. Arinze Uzochukwu. PhD`,
                ArinzeUzochukwu,
                `ENUGU USA National President 2008 - 2010`,
                `Mmuta Amaka I na Ancient Mgbidi Kingdom. 2008 - 2010 EnuguUSA National President. Special United States 🇺🇸 Federal Government Employee, SGE. Chair, Clean Air Status and Trends Network Panel (CAST Net)  of the United States Environmental Protection Agency in the Biden Administration.
          `
              )
            }
          >
            <img src={ArinzeUzochukwu} alt="" />
            <div class="content">
              <h3>
                Senior Prof. G. Arinze Uzochukwu. PhD, Mmuta Amaka I na Ancient
                Mgbidi Kingdom
              </h3>
              <p id="position">ENUGU USA National President 2008 - 2010</p>
            </div>
          </div>

          <div
            class="card"
            onClick={() =>
              showInfoModal(
                `HIGH CHIEF DR SIR EMMA OBIESIE`,
                SirEmma,
                `EMERITUS PRESIDENT ENUGU USA ( JULY 2012 to JUNE 2014).`,
                `<h3>
                EZE UGO NA IWOLLO OMASI</br>
                AGBALANZE NA EZEAGU IGBUDU</br>
                OZO NNABUENYI.</h3>
                <p>FOUNDER AND FIRST PRESIDENT ENUGU ASSOCIATION OF SOUTH FLORIDA.ENUGU/USA, CHAIRMAN ECONOMIC DEVELOPMENT COMMITTEE, 2004 to 2008  </p> <ul><h3>EDUCATION:</h3> <li>NOVA SOUTHEASTERN UNIVERSITY, FORT LAUDERDALE, FLORIDA.</li> <li>DOCTOR OF INTERNATIONAL BUSINESS ADMINISTRATION/FINANCE, JULY 2006</li> <li> ST. THOMAS UNIVERSITY, MIAMI, FLORIDA. </li> <li>MBA, AUGUST 1985</li> <li>ST THOMAS LAW SCHOOL</li> <li>LL.M HUMAN RIGHTS, MAY 2020.</li> <li>FLORIDA INTERNATIONAL UNIVERSITY, MIAMI, FLORIDA</li> <li>BACHELOR OF BUSINESS ADMINISTRATION, FINANCE, AUGUST 1982.</li></ul>
                <ul><h3>PUBLICATION:</h3> <li>THE TIMELY POSITIONING OF BANKS FOR ACQUISITIONS AND MERGERS.</li> </ul>
                <p>MEMBER: SIGMA BETA DELTA SOCIETY.</p>
                <p>OWNER CROWN MORTGAGE CORPORATION. </br> OKAN INTERNATIONAL UNIVERSITY, DAVIE FLORIDA/TURKEY </br> PROFESSOR/DEAN </br> CONSULTANT/HOUSING</p>
                <ol><h3>ACCOMPLISHMENTS:</h3> <li>RAISED CLOSE TO ONE HUNDRED THOUSANDS OF DOLLARS IN MY ADMINISTRATION</li> <li>⁠WAS ABLE TO INVITE A LOT OF ENUGU STATE COMMISSIONERS AND OTHER GOVERNMENT OFFICIALS.</li> <li> ⁠DOCTORS GAVE LECTURES TO MEDICAL STUDENTS AND WOMEN. </li> <li>⁠VISITED MANY VILLAGES FOR MEDICAL MISSION AND BROUGHT THE ONES THAT ARE VERY SICK TO THE CITY FOR CONTINUING TO CARE FOR THEM.</li> <li>⁠OTHERS.</li></ol>
                
                 `
              )
            }
          >
            <img src={SirEmma} alt="" />
            <div class="content">
              <h3>High Chief Dr. Emma Obiesie </h3>
              <p id="position">
                ENUGU USA National President ( 2012 to 2014).
              </p>
            </div>
          </div>
          <div
            class="card"
            onClick={() =>
              showInfoModal(
                `Chief/Dr/Mrs. Ife Sinachi Ugwuonye-Okechukwu`,
                IfeSinachi,
                `ENUGU USA President 2014 - 2016`,
                `
               <ul>
                <li>Acquisition of Enugu USA Office in Enugu </li>
                <li>Creation of Nigerian Resident Officer of Enugu USA Medical Mission  </li>
                <li>Appointment of Female top officers of the different main branches such as:
                <ul>
                  <li>Managing Director of Enugu USA Medical Mission </li>
                  <li>Coordinator Enugu USA Medical Mission</li>
                  <li>Core collaboration with the Enugu State Government.</li>
                </ul>
                </li>
                <li>Launching of Enugu USA Sweet Sixteen Magazine</li>
                <li>Enthroning a more conducive and balance channel where members are non- members thrived in collaboration.  </li>
                <li>Reconciliation of different factions to create a more friendly and peaceful organization  </li>
                <li>Created many departments and appointments to encourage maximum involvement.   </li>
                <li>Setting up a system to conduct the medical mission at several local government areas at the same time, thereby making it more efficient and more areas benefiting from the scheme.   </li>
                <li>Putting Enugu USA in the map through high tech media… both electronic and print</li>
                <li>Acquisition of Enugu USA Office in Enugu</li>
                <li>Creation of Nigerian Resident Officer of Enugu USA Medical Mission </li>
                <li>Setting up of Enugu USA website </li>
                <li>First Enugu USA Convention in Las Vegas. 2015, with a keynote speech delivered by a top Nigerian Senator/Officer of the Senate. </li>
                <li>⁠Election year Enugu USA very organized Convention in Los Angeles 2016</li>
                <li>⁠First ever involvement of the Enugu State Traditional Rulers, and the nomination and confirmation of Traditional Chieftaincy Titles on deserving members. </li>
                <li>⁠Peaceful transition to new leadership following free and fair election. 2016 </li>
                <li>Ensured the supreme authority of the constitution and adhered to its dictates.</li>
               </ul>
               `
              )
            }
          >
            <img src={IfeSinachi} alt="" />
            <div class="content">
              <h3>Chief/Dr/Mrs. Ife Sinachi Ugwuonye-Okechukwu</h3>
              <p id="position">ENUGU USA President 2014 - 2016</p>
            </div>
          </div>
          <div
            class="card"
            // onClick={() =>
            //   showInfoModal(
            //     `Chief Chukwuma Calistus Ojukwu`,
            //     Chukwuma,
            //     `EMERITUS PRESIDENT ENUGU USA ( 2016 - 2018).`,
            //     `
                
            //      `
            //   )
            // }
          >
            <img src={Chukwuma} alt="" />
            <div class="content">
              <h3>Chief Chukwuma Calistus Ojukwu </h3>
              <p id="position">
                ENUGU USA National President ( 2016 to 2018).
              </p>
            </div>
          </div>

          <div
            class="card"
            // onClick={() =>
            //   showInfoModal(
            //     `Atty. Dr. Celestine Ude`,
            //     AttyCelestine,
            //     `EMERITUS PRESIDENT ENUGU USA ( 2018 - 2022).`,
            //     `
                
            //      `
            //   )
            // }
          >
            <img src={AttyCelestine} alt="" />
            <div class="content">
              <h3>Atty. Dr. Celestine Ude </h3>
              <p id="position">
                ENUGU USA National President ( 2018 to 2022).
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class={modal ? "executivesDetails" : "hide"}>
        <div class="executivesDetailsWrap">
          <div class="executiveOverlay">
            <p class="close" onClick={() => setModal(false)}>
              x
            </p>
            <img src={image} alt="" />
            <div class="content">
              <div class="head">
                <h3>{name}</h3>
                <p>{title}</p>
              </div>
              <p id="description" class="description"></p>
              <div class="contact">
                <h3>Contact Me</h3>
                <div class="icons">
                  <i class="bi bi-facebook"></i>
                  <i class="bi bi-whatsapp"></i>
                  <i class="bi bi-twitter-x"></i>
                  <i class="bi bi-instagram"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PresidentEmemritus;
