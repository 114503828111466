import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { NavLink } from "react-router-dom";
import "../styles/MemebershipChapters.css";

const MembershipChapters = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />

      <section class="membershipChaptersWrap">
        <div class="heading">
          <h2>Our Membership Chapters</h2>
          <p>
            Enugu USA's membership chapters serve as vibrant community hubs
            across the United States, fostering connections, promoting cultural
            heritage, and spearheading impactful initiatives to support Enugu
            State and its residents. With dedicated members in various cities,
            each chapter plays a crucial role in advancing Enugu USA's mission
            of empowerment, unity, and service, ensuring that the organization's
            reach and impact extend far and wide.
          </p>
        </div>
        <hr />
        <div class="memebershipChaptersCardWrapper">
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Enugu State Association of North Carolina(ESANC)/Screenshot_20230815-215613_WhatsApp.jpg")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Enugu State Association of North Carolina(ESANC)/Screenshot_20230815-215613_WhatsApp.jpg"),
                  "_blank"
                )
              }
            />
            <h3>Enugu State Association of North Carolina(ESANC)</h3>
            <p>
              Uniting Enugu State natives in North Carolina to promote cultural
              heritage, community engagement, and collaborative initiatives for
              collective growth and prosperity.
            </p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Enugu Progressive Ladies Association of Southern California/PHOTO-2023-08-16-00-34-33.jpg")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Enugu Progressive Ladies Association of Southern California/PHOTO-2023-08-16-00-34-33.jpg"),
                  "_blank"
                )
              }
            />
            <h3>
              Enugu Progressive Ladies Association of Southern California.
            </h3>
            <p>
              Empowering women, fostering community connections, and driving
              positive change in Southern California while celebrating Enugu
              State's rich heritage.
            </p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Global Ladies of Enugu State Association Washington DC Metropolitan Inc. (GLES)/PHOTO-2023-07-22-10-21-15.jpg")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Global Ladies of Enugu State Association Washington DC Metropolitan Inc. (GLES)/PHOTO-2023-07-22-10-21-15.jpg"),
                  "_blank"
                )
              }
            />
            <h3>
              Global Ladies of Enugu State Association Washington DC
              Metropolitan Inc. (GLES)
            </h3>
            <p>
              Uniting Enugu State natives in the Washington DC Metropolitan area
              to empower, support, and advocate for each other while promoting
              cultural pride and community engagement.
            </p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Ofuobi Women of Enugu State WDC Inc/PSX_20230814_211347.jpg")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Ofuobi Women of Enugu State WDC Inc/PSX_20230814_211347.jpg"),
                  "_blank"
                )
              }
            />
            <h3>Ofuobi Women of Enugu State WDC Inc</h3>
            <p>
              Uniting Enugu State natives in the Washington DC area with a
              shared purpose, fostering solidarity, and promoting empowerment
              through collective action and support.
            </p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Umuada Wawa USA, Houston Texas/7974e4f7-bed7-456a-855e-0359be537e84.jpg")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Umuada Wawa USA, Houston Texas/7974e4f7-bed7-456a-855e-0359be537e84.jpg"),
                  "_blank"
                )
              }
            />
            <h3>Umuada Wawa USA, Houston, Texas</h3>
            <p>
              Empowering women, fostering community bonds, and championing
              development initiatives in Houston, Texas, while celebrating the
              rich cultural heritage of Enugu State
            </p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Enugu State Elite Women Association of Southern California (ESEWAC)/image.png")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Enugu State Elite Women Association of Southern California (ESEWAC)/image.png"),
                  "_blank"
                )
              }
            />
            <h3>
              Enugu State Elite Women Association of Southern California
              (ESEWAC)
            </h3>
            <p>
              Bringing together accomplished women from Enugu State in Southern
              California to empower, inspire, and uplift each other while
              contributing to the community's advancement and cultural
              preservation.
            </p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Enugu State Women Organization Dallas/image.png")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Enugu State Women Organization Dallas/image.png"),
                  "_blank"
                )
              }
            />
            <h3>Enugu State Women Organization DALLAS FORTWORTH</h3>
            <p>
              Empowering Enugu State women in the Dallas Fort Worth area through
              unity, advocacy, and community engagement while celebrating our
              cultural heritage.
            </p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Enugu State Association (ESA) Nashville TN.jpg")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Enugu State Association (ESA) Nashville TN.jpg"),
                  "_blank"
                )
              }
            />
            <h3>Enugu State Association (ESA) Nashville TN.</h3>
            <p>
              ESA has been in existence for little more than 13 years now.
              Membership comprises of Enugu state indigens, men and women,
              resident in Middle Tennessee and extends to anyone in Tennessee.
              Hon. Engr Sylvester Ene is the current president of ESA.
            </p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/The Enugu State Association Washington DC Men.jpg")}
              alt=""
              style={{ objectPosition: "top" }}
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/The Enugu State Association Washington DC Men.jpg"),
                  "_blank"
                )
              }
            />
            <h3>Enugu State Association Washington DC Men.jpg</h3>
            <p></p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Enugu AZ (3).PNG")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Enugu AZ (3).PNG"),
                  "_blank"
                )
              }
            />
            <h3>Enugu USA Arizona Chapter</h3>
            <p></p>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/Houston Enugu State Organization (HESO) Chapter/PHOTO-2023-06-28-06-58-29.jpg")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/Houston Enugu State Organization (HESO) Chapter/PHOTO-2023-06-28-06-58-29.jpg"),
                  "_blank"
                )
              }
            />
            <h3>Houston Enugu State Organization (HESO) Chapter</h3>
            <p>
              HESO ranks top among the greatest Nigerian socio-cultural
              organisations in North America with over one hundred registered
              members...
            </p>
            <NavLink to={"HESO"}>
              Read More
              <span class="material-symbols-outlined"> arrow_right_alt </span>
            </NavLink>
          </div>
          <div class="membershipChapterCard">
            <img
              src={require("../assets/images/Chapters/EPU TEXAS.jpg")}
              alt=""
              onClick={() =>
                window.open(
                  require("../assets/images/Chapters/EPU TEXAS.jpg"),
                  "_blank"
                )
              }
            />
            <h3>Enugu Progressive Union (EPU) Houston Texas</h3>
            <p>
              
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MembershipChapters;
