import { useNavigate } from "react-router";
import "../styles/success.css";

const Success = () => {
  const navigate = useNavigate();

  return (
    <>
      <section class="successWrapper">
        <span class="material-symbols-outlined"> check_circle </span>
        <h2>Success</h2>
        <h3>
          Thank you for your donation. If you have any questions, please email
          orders@enugu_usa.com.
        </h3>
        <p>Click button below to go back to the home page</p>
        <button onClick={() => navigate("/")}>Done</button>
      </section>
    </>
  );
};

export default Success;
