import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/MedicalMission.css";

const MedicalMission2023 = () => {
  return (
    <>
      <Navbar />
      <section class="heroSection2023">
        <div class="MheroOverlay">
          <h2>2023 MEDICAL MISSION</h2>
          <p></p>
        </div>
      </section>
      <div class="Mcontent">
        <p>
          Embark on a journey of healing and hope with our successful 2023
          medical mission themed "Health is Wealth." In select communities of
          Enugu, including Diogbe, Amurri, Mgbowo, Agbudu, Amokwe, and more,
          we've extended a lifeline of care and compassion to those in need.
          Through this mission, we've brought vital healthcare services directly
          to the doorstep of underserved populations, prioritizing the
          well-being of our communities and emphasizing the invaluable asset of
          good health. Join us in making a lasting impact and spreading the
          message that true wealth begins with a healthy body and mind.
        </p>
      </div>
      <div class="galleryWrap">
        <div class="heading">
          <h2>Our Gallery</h2>
          <p>
          Explore the heartwarming moments captured during Enugu USA's transformative 2023 medical outreach, where dedicated professionals and volunteers brought free healthcare services and hope to underserved communities in Enugu State, Nigeria.
          </p>
        </div>
        <div class="imagesWrapper2023">
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4387.jpg")}
            alt=""
            class="pic1"
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4388.jpg")}
            alt=""
            class="pic2"
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4403.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_0076.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4597.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_0151.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4726.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4747.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4804.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4933.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_4851.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_5038.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_5058.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_6017.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2023 Medical Mission/DSC_5949.jpg")}
            alt=""
          />
        </div>
      </div>

      <section class="videoWrapper">
        <div class="section videoSet">
          <div class="leftMContent">
            <h2>ENUGU USA 2023 MEDICAL MISSION OUTREACH INTERVIEWS</h2>
            <p>
            Experience the essence of Enugu USA 2023 Medical Mission Outreach through our interview video. Witness our commitment to community service and health promotion. Join us on this meaningful journey.02
            </p>
          </div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/8itxCr7vTrY?si=mCdKdEwEnndFm0b5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        
        
      </section>

      <Footer />
    </>
  );
};

export default MedicalMission2023;
