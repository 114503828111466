import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/Terms.css";

const Terms = () => {
  return (
    <>
      <Navbar />

      <section class="terms">
        <div class="heading">
          <h2>Terms and Conditions of Use</h2>
          <p>
            Welcome to the Enugu USA website. By accessing or using this
            website, you agree to comply with and be bound by the following
            terms and conditions of use. Please read these terms carefully
            before using our website.
          </p>
        </div>
        <ol>
          <li>
            <span>Acceptance Of Terms:</span>
            By accessing or using the Enugu USA website, you agree to be bound
            by these Terms and Conditions of Use. If you do not agree with any
            part of these terms, you may not use or access this website.
          </li>
          <li>
            <span>Use of Website:</span>
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness, or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors, and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </li>
          <li>
            <span>Intellectual Property: </span>
            This website contains material which is owned by or licensed to us.
            This material includes, but is not limited to, the design, layout,
            look, appearance, and graphics. Reproduction is prohibited other
            than in accordance with the copyright notice, which forms part of
            these terms and conditions.
          </li>
          <li>
            <span>Links to Other Websites: </span>
            From time to time, this website may also include links to other
            websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the
            website(s). We have no responsibility for the content of the linked
            website(s).
          </li>
          <li>
            <span>Privacy Policy: </span>
            Your use of this website is also subject to our Privacy Policy,
            which can be found (insert link to Privacy Policy). By using this
            website, you consent to the collection and use of your information
            as described in the Privacy Policy.
          </li>
          <li>
            <span>Limitation of Liability: </span>
            Your use of any information or materials on this website is entirely
            at your own risk, for which we shall not be liable. It shall be your
            own responsibility to ensure that any products, services, or
            information available through this website meet your specific
            requirements.
          </li>
          <li>
            <span>Changes to Terms and Conditions: </span>
            We reserve the right to modify these terms and conditions at any
            time. Such modifications shall be effective immediately upon posting
            on this website. Your continued use of the website after any
            modifications indicates your acceptance of the updated terms and
            conditions.
          </li>
          <li>
            <span>Governing Law: </span>
            Your use of this website and any dispute arising out of such use of
            the website shall be governed by and construed in accordance with
            the laws of the United States. You agree to submit to the
            jurisdiction of, and agree that venue is proper in, these courts in
            any such legal action or proceeding.
          </li>
        </ol>
        <p>
          If you have any questions about these Terms and Conditions of Use,
          please contact us at
        </p>
      </section>

      <Footer />
    </>
  );
};

export default Terms;
