import { NavLink } from "react-router-dom";
import "../styles/Navbar.css";
import { FaChevronDown } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { RiMenu3Line } from "react-icons/ri";
import logo from "../assets/images/enugulogo.png";

const Navbar = () => {
  const [showMedicalMission, setShowMedicalMission] = useState(false);
  const [showPages, setShowPages] = useState(false);
  const [showExecutives, setShowExecutives] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    console.log(showNavbar);
  }, [showNavbar]);

  const showNav = () => {
    setShowNavbar(!showNavbar);
    console.log(showNavbar);
  };
  const closeModals = () => {
    setShowPages(false);
    setShowNavbar(false);
  };
  const showNavPages = () => {
    setShowPages(!showPages);
    setShowExecutives(false);
  };
  const showNavExecutives = () => {
    setShowExecutives(!showExecutives);
    setShowPages(false);
  };
  const activeStyle = {
    color: "#0f9c1d",
  };
  return (
    <>
      <nav className="nav" onMouseLeave={closeModals}>
        <div className="logo">
          <img src={logo} alt="" />
        </div>

        {window.innerWidth > 1000 || showNavbar ? (
          <ul>
            <NavLink to={"/"}> Home </NavLink>
            <NavLink to={"/about"}> About Us </NavLink>
            <NavLink to={"/donations"}> Donations </NavLink>
            <li>
              <span onClick={showNavExecutives}>
                Executives
                <span className="material-symbols-outlined icon">
                  {" "}
                  expand_more{" "}
                </span>
              </span>
              <div className={showExecutives ? "exMenu" : "hide"}>
                <NavLink to={"/currentexecutives"}>
                  {" "}
                  Current Executives{" "}
                </NavLink>
                <NavLink to={"/currentbodleadership"}>
                  {" "}
                  Current BOD Leadership{" "}
                </NavLink>
                <NavLink to={"/pastbodleadership"}> Past BOD Chiarman </NavLink>
                <NavLink to={"/presidentemeritus"}>
                  {" "}
                  President Emeritus{" "}
                </NavLink>
                <NavLink to={"/medical-mission-executives"}>
                  {" "}
                  Medical Mission Executives{" "}
                </NavLink>
              </div>
            </li>
            <li>
              <span onClick={showNavPages}>
                What We do
                <span className="material-symbols-outlined icon">
                  {" "}
                  expand_more{" "}
                </span>
              </span>
              <div className={showPages ? "menu" : "hide"}>
                <NavLink to={"/2023-convention"}> 2023 Conventions</NavLink>
                <NavLink to={"/Our-Youths"}> Our Youths</NavLink>
                <div className="medical-mission" to="/">
                  <p onClick={() => setShowMedicalMission(!showMedicalMission)}>
                    Medical Mission
                    <span className="material-symbols-outlined">
                      {" "}
                      expand_more{" "}
                    </span>
                  </p>
                  <div className={showMedicalMission ? "mission-menu" : "hide"}>
                    {/* <NavLink to="2023-medical-mission.html"> 2023 Medical Mission </NavLink>  */}
                    <NavLink to={"/2023-Medical-Mission"}>
                      {" "}
                      2023 Medical Mission{" "}
                    </NavLink>
                    <NavLink to={"/2022-Medical-Mission"}>
                      {" "}
                      2022 Medical Mission{" "}
                    </NavLink>
                    <NavLink to={"/2019-Medical-Mission"}>
                      {" "}
                      2019 Medical Mission{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
            </li>
            <NavLink to={"/Education-Mission"}>Education Mission</NavLink>
            <NavLink to={"/project"}>Project</NavLink>
            <NavLink to={"/contact-us"}>Contact Us</NavLink>
            <NavLink
              className="login-button"
              to="https://enugu-usa-dashboard.vercel.app/login"
            >
              Login
            </NavLink>
          </ul>
        ) : undefined}
        <div className="loginWrapper">
          {/* <div className="nav-profile">
                <img src="" alt="" />
                <p>John Doe</p>
                <FaChevronDown />
              </div>  */}
          <NavLink to={"https://enugu-usa-dashboard.vercel.app/login"}>
            {" "}
            Login{" "}
          </NavLink>
        </div>
        <div className="menu-icon" onClick={() => setShowNavbar(!showNavbar)}>
          <span className="material-symbols-outlined"> menu </span>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
