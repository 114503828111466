import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import contact from "../assets/images/enugulogo.png";
import "../styles/Contact.css";
import { EMAILJS_PUBLIC_KEY, EMAILJS_SERVICE_ID, EMAILJS_CONTACT_TEMPLATE_ID } from "../utils/secretKeys";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { url } from "../utils/url";
import axios from "axios";
import { useRef, useState } from "react";

const ContactUs = () => {
  const form2 = useRef();
  const [loading, setLoading] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs.sendForm(
        EMAILJS_SERVICE_ID,
        EMAILJS_CONTACT_TEMPLATE_ID,
        form2.current,
        EMAILJS_PUBLIC_KEY
    ).then(
        (result) => {
            console.log(result);
            setLoading(false)
            toast.success("Message Sent Successfully");
            form2.current.reset();
        },
        (error) => {
            setLoading(false)
            console.log(error.text);
        }
      );
  };

  
  return (
    <>
      <Navbar />

      <section className="contactWrapper">
        <div className="leftContact">
          <div className="heading">
            <h2>Contact Us</h2>
            <p>
              Feel free to reach out to us with your questions, feedback, or
              inquiries about EnuguUSA, we're here to assist you. Simply fill
              out the form below, and we'll respond to you as soon as possible.
            </p>
          </div>
          <form action="" ref={form2} >
            <div className="inputWrapper">
              <label for="">Name:</label>
              <input type="text" placeholder="John Doe" name="name" />
            </div>
            <div className="inputWrapper">
              <label for="">Email Address:</label>
              <input type="text" placeholder="abc@example.com"  name="email"/>
            </div>
            <div className="inputWrapper">
              <label for="">Message:</label>
              <textarea name="message" id="" cols="30" rows="4"></textarea>
            </div>
            <button type="submit" onClick={sendEmail} disabled={loading}>{loading ? "Loading..."  : "Submit"}</button>
          </form>
        </div>
        <div className="rightContact">
          <img src={contact} alt="" />
        </div>
      </section>

      <section className="contactInfo">
        {/* <div className="contactCard">
          <i className="bi bi-telephone-fill"></i>
          <p>Call us on:</p>
          <h3>+124 03814267</h3>
        </div> */}
        <div className="contactCard middle">
          <i className="bi bi-geo-alt-fill"></i>
          <p>Locate our office at:</p>
          <h3>
          Enugu USA Contact 8431 Bates Drive Bowie Maryland, 20720, U.S.A
          </h3>
        </div>
        <div className="contactCard">
          <i className="bi bi-envelope-fill"></i>
          <p>Email us at:</p>
          <h3>info@enuguusa.net</h3>
        </div>
      </section>

      <section className="connectWrapper">
        <h2>Connect With Us</h2>
        <div className="connectIcons">
          <i className="bi bi-whatsapp"></i>
          <i className="bi bi-facebook"></i>
          <i className="bi bi-discord"></i>
          <i className="bi bi-twitter-x"></i>
          <i className="bi bi-instagram"></i>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ContactUs;
