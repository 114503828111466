import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/MedicalMission.css";

const MedicalMission2019 = () => {
  return (
    <>
      <Navbar />

      <section class="heroSection2019">
        <div class="MheroOverlay">
          <h2>2019 MEDICAL MISSION</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias
            aspernatur quidem ea eveniet amet accusantium nihil odio officia
            autem modi. Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Eos, unde.
          </p>
        </div>
      </section>
      <div class="Mcontent">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
          delectus magni, inventore in molestiae quisquam, iste at culpa
          dignissimos expedita aut laboriosam beatae quod odit earum adipisci
          totam accusantium vero pariatur distinctio maiores repudiandae
          voluptatum? Quisquam rem at a labore natus assumenda quidem unde
          maiores. Ut dicta, tempora ducimus, ipsam illo ea fugit impedit et a
          adipisci libero sunt vitae ab, voluptates reiciendis perspiciatis
          praesentium aperiam sapiente maiores nesciunt natus quia est! Error
          quos molestias soluta? A, laborum. Tenetur reiciendis necessitatibus
          fugit deserunt in provident, cum placeat, modi accusamus officiis
          voluptate possimus, illum aut magnam consequatur dignissimos quasi sed
          adipisci eos esse nihil? Libero impedit, sequi laudantium reiciendis
          provident reprehenderit? Exercitationem, fugit sit deserunt non
          expedita quidem possimus ullam praesentium nemo maiores laudantium
          repudiandae placeat natus! Labore obcaecati amet cum molestias, omnis
          tenetur, quia qui doloribus quis, quo ducimus. Labore reiciendis
          expedita porro dignissimos ipsam sint totam eveniet nisi voluptas
          neque odio reprehenderit explicabo soluta voluptatibus ipsa, mollitia
          nulla laudantium nihil ab magnam? Veniam, facilis maxime? Ex
          repudiandae est voluptates reiciendis reprehenderit. Sequi delectus
          accusantium eaque commodi animi placeat voluptas! Nihil tenetur
          corrupti vero reiciendis facere, dolores totam odit vel, libero
          dolorum quisquam ad ipsa earum numquam eius ipsam ratione?
        </p>
      </div>
      <div class="galleryWrap" style={{ marginBottom: "100px" }}>
        <div class="heading">
          <h2>Our Gallery</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
            dolorum tempora soluta explicabo, accusantium quam numquam
            voluptatem rem blanditiis, cum illo, corporis ea! Molestias, alias!
          </p>
        </div>
        <div class="imagesWrapper">
          <img
            src={require("../assets/images/2019MedicalMission/166e3151-6b86-4d62-9e5c-e4dbd45456f0.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/1b71d096-9507-48f5-bcd8-f63cb3c0181a.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/3241c96b-1f78-4326-ad24-1561e6dd4098.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/36da19ca-e6f3-4e1b-a11b-9eacbd0418bf.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/87fe7ec1-43fb-4d2f-88a4-b02079afe1b5.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/aeff9138-9dc1-4c6c-a9ff-8fea9ce88b24.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_1101.jpg")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9325.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9330.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9338.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9399.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9387.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9386.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9383.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9376.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9368.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9363.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9358.JPG")}
            alt=""
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9346.JPG")}
            alt=""
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MedicalMission2019;
