import Navbar from "../components/Navbar"
import "../styles/EducationMission.css"


const EducationMission = () => {

    return<>
        <Navbar/>
        <div className="educationMissionWrapper">
        <h1>Coming Soon🔥🔥</h1>
        </div>
    </>

}

export default EducationMission