import { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/Donation.css";
import barcode from "../assets/images/ENUGU USA MEDICAL MISSON PAYPAL DONATION QR Code.png";
import stripebarcode from "../assets/images/stirpe qr.jpg"

import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

const Donations = () => {
  const [tab, setTab] = useState("donations");
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [modal, setModal] = useState(false);
  const [amountInput, setAmountInput] = useState(false);

  const onChangeAmount = (value) => {
    if (value === "other") {
      setAmountInput(true);
    } else {
      setAmountInput(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const {stripe, elements} = this.props;

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    const res = await fetch('/create-intent', {
      method: 'POST',
    });

    const {client_secret: clientSecret} = await res.json();

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      <Navbar />
      <section class="donationsWrapper">
        <div class="content">
          <h1>Donations / Subscriptions</h1>
          <p>
            Your generosity fuels our mission to make a positive impact in Enugu
            State and beyond. Every donation contributes directly to vital
            community projects, healthcare initiatives, educational programs,
            and empowerment efforts, ensuring a brighter future for all.
            Together, we can build stronger communities and transform lives.
            Thank you for your support!
          </p>
          <a href="#donate">Donate</a>
        </div>
        <img src={require("../assets/images/collage.jpg")} alt="" />
      </section>

      {/* <!-- DONATIONS / SUBSCRIPTIONS --> */}
      <section class="donationForm" id="donate">
        {/* <div class="tab">
          <button
            class={`donation-button ${
              tab === "donations" ? "active" : undefined
            }`}
            onClick={() => setTab("donations")}
          >
            Donations
          </button>
          <button
            class={`subscription-button ${
              tab === "subscriptions" ? "active" : undefined
            }`}
            onClick={() => setTab("subscriptions")}
          >
            Subscriptions
          </button>
        </div> */}
        <div class={tab === "donations" ? "donationFormWrapper" : "hide"}>
          <div class="leftDonation">
            <h2>Make a one time or donation to support our mission.</h2>
            <p>
              Your generous contribution helps fund vital programs, initiatives,
              and projects aimed at fostering unity, education, healthcare, and
              economic development in Enugu State and beyond. Together, we can
              make a meaningful difference and create lasting positive change
              for generations to come. Donate now and be a part of our journey
              towards a brighter future.
            </p>
            <div class="stats">
              <div class="statCard">
                <h3>500+</h3>
                <p>People Donated</p>
              </div>
            </div>
          </div>

          <div className="rightDonation">
            <h2>Donate With</h2>
            <div className="tabs">
              <button
                className={paymentMethod === "stripe" ? "active" : undefined}
                onClick={() => setPaymentMethod("stripe")}
              >
                Stripe
              </button>
              <button
                className={paymentMethod === "paypal" ? "active" : undefined}
                onClick={() => setPaymentMethod("paypal")}
              >
                PayPal
              </button>
              <button
                className={paymentMethod === "zelle" ? "active" : undefined}
                onClick={() => setPaymentMethod("zelle")}
              >
                Zelle
              </button>
              <button
                className={
                  paymentMethod === "wireTransfer" ? "active" : undefined
                }
                onClick={() => setPaymentMethod("wireTransfer")}
              >
                Wire Transfer
              </button>
            </div>

            {paymentMethod === "stripe" ? (
              <form className={paymentMethod === "stripe" ? "stripe" : "hide"}>
                <img src={stripebarcode} alt="" />
                {/* <p className="email">
                  <b>Email Address: </b> enugumedmission@gmail.com
                </p> */}
                <button
                  id="customAmount"
                  onClick={() => {
                    window.open(
                      "https://donate.stripe.com/test_eVaeYa9QDafi1tCfYY",
                      "_blank"
                    );
                  }}
                >
                  Donate Now
                </button>
              </form>
            ) : paymentMethod === "paypal" ? (
              <form className={paymentMethod === "paypal" ? "paypal" : "hide"}>
                <img src={barcode} alt="" />
                <p className="email">
                  <b>Email Address: </b> enugumedmission@gmail.com
                </p>
                <button
                  id="customAmount"
                  onClick={() => {
                    window.open(
                      "https://www.paypal.com/donate/?hosted_button_id=WK3STXVHSDR7L",
                      "_blank"
                    );
                  }}
                >
                  Donate Now
                </button>
              </form>
            ) : paymentMethod === "zelle" ? (
              <form className={paymentMethod === "zelle" ? "zelle" : "hide"}>
                {/* <img src={barcode} alt="" /> */}
                <p className="email">
                  <b>Email Address: </b> enugumedmission@gmail.com
                </p>
                <button
                  id="customAmount"
                  onClick={() => {
                    window.open(
                      "https://www.paypal.com/donate/?hosted_button_id=WK3STXVHSDR7L",
                      "_blank"
                    );
                  }}
                >
                  Donate Now
                </button>
              </form>
            ) : (
              <form action="" className="wireTransfer">
                <h3>ENUGU USA MEDICAL MISSION INC. </h3>
                <h3>Bank of America</h3>
                <p>
                  <span>Account Number: </span> 325106963988
                </p>
                <p>
                  <span>Routing Number: </span> 121000358
                </p>
                <p>
                  <span>SWIFT Code: </span> BOFAUS3N (US Dollars)
                </p>
                <p>
                  <span>SWIFT Code: </span> BOFAUS6S (Other Currencies)
                </p>
              </form>
            )}
          </div>
        </div>
        <div class={tab === "subscriptions" ? "donationFormWrapper" : "hide"}>
          <div class="leftDonation">
            <h2>Make recurring Subscriptions for the mission</h2>
            <p>
              Your generous contribution helps fund vital programs, initiatives,
              and projects aimed at fostering unity, education, healthcare, and
              economic development in Enugu State and beyond. Together, we can
              make a meaningful difference and create lasting positive change
              for generations to come. Donate now and be a part of our journey
              towards a brighter future.
            </p>
            <div class="stats">
              <div class="statCard">
                <h3>500+</h3>
                <p>Members Donated</p>
              </div>
            </div>
          </div>
          <form action="">
            <h2>Subscribe</h2>
            <div class="inputWrapper">
              <label for="">Name:</label>
              <input type="text" name="" id="" placeholder="John Doe" />
            </div>
            <div class="inputWrapper">
              <label for="">Email Address:</label>
              <input type="text" name="" id="" placeholder="abc@example.com" />
            </div>
            <div class="inputWrapper">
              <label for="">Amount:</label>
              <input type="text" name="" id="" placeholder="50000" />
            </div>
            <div class="inputWrapper">
              <label for="">Subscription:</label>
              <input type="text" name="" id="" placeholder="50000" />
            </div>
            <button C="donationModal()">Donate Now</button>
          </form>
        </div>
        <img
          src={require("../assets/images/Super thank you-amico 1.png")}
          alt=""
          className="thankyousvg"
        />
      </section>

      {/* <!-- NEWSLETTER --> */}

      <div class="newsletterWrapper">
        <div class="newsletterOverlay">
          <h3>Subscribe to our NewsLetter</h3>
          <form action="">
            <input type="email" placeholder="Enter Email Address" required />
            <button>Submit</button>
          </form>
          <div class="iconWrapper">
            <i class="bi bi-facebook"></i>
            <i class="bi bi-whatsapp"></i>
            <i class="bi bi-instagram"></i>
          </div>
          <img src="" alt="" />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Donations;
