import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/convention.css";

const Convention2023 = () => {
  return (
    <>
      <Navbar />
      <section class="eventInfoWrapper">
        <div className="eventInfoOverlay">
          <div class="leftEventInfo">
            <h2>25th Anniversary Silver Jubilee Celebration</h2>
            <p>
              CONTINUITY OF HEALTHCARE DELIVERY AND TRAINING FOR ALL WITH A FOCUS
              ON IMPROVED AND SUSTAINABLE CARE IN ENUGU STATE
            </p>
            <a
              href={require("../assets/documents/2023 CONVENTION PRESIDENTIAL SPEECH.pdf")}
              target="_blank"
            >
              <i class="bi bi-download"></i> Presidents Speech{" "}
            </a>
            <a
              href={require("../assets/documents/2023 Enugu USA Convention Digital Brochure-1.pdf")}
              target="_blank"
            >
              <i class="bi bi-download"></i> Brochure
            </a>
            <p class="statInfo">
              <i class="bi bi-people"></i>
              2000 antendees
            </p>
            <p class="statInfo">
              <i class="bi bi-calendar-event"></i>
              Gala Night - 2nd September 2023
            </p>
            <p class="statInfo">
              <i class="bi bi-geo-alt"></i>
              METRO POINTS HOTEL 8500 Annapolis Road, New Carrollton, Maryland
              20784
            </p>
          </div>
          <div class="rightEventInfo">
            <iframe
              src="https://player.vimeo.com/video/876355472?h=b5a14a55ff"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>

      {/* <!-- ARTICLES --> */}
      {/* <!-- <div class="articlesWrapper">
            <div class="head">
                <h2>Our Articles</h2>
                <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
                nesciunt nulla nisi perferendis hic neque voluptas nemo rem fugiat
                quas?
                </p>
            </div>
            <div class="eventsCardWrapper">
                <div class="eventsCard">
                <img src="" alt="" />
                <div class="content">
                    <div class="heading">
                    <p>Written by John Doe</p>
                    <h3>Event Article Heading</h3>
                    </div>
                    <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore
                    quos amet quis animi ea, reiciendis provident dignissimos optio
                    laborum minima!
                    </p>
                    <button onclick="navigate('article.html')">Read Article</button>
                </div>
                </div>
                <div class="eventsCard">
                <img src="" alt="" />
                <div class="content">
                    <div class="heading">
                    <p>Written by John Doe</p>
                    <h3>Event Article Heading</h3>
                    </div>
                    <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore
                    quos amet quis animi ea, reiciendis provident dignissimos optio
                    laborum minima!
                    </p>
                    <button>Read Article</button>
                </div>
                </div>
                <div class="eventsCard">
                <img src="" alt="" />
                <div class="content">
                    <div class="heading">
                    <p>Written by John Doe</p>
                    <h3>Event Article Heading</h3>
                    </div>
                    <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore
                    quos amet quis animi ea, reiciendis provident dignissimos optio
                    laborum minima!
                    </p>
                    <button>Read Article</button>
                </div>
                </div>
            </div>
            </div> --> */}

      {/* <!-- GALLERY --> */}
      <div class="galleryWrapper">
        <div class="head">
          <h2>Our Gallery</h2>
          <p>
            Step into our 25th Anniversary Silver Jubilee Celebration gallery,
            where cherished moments of unity and progress come to life. Explore
            highlights from this milestone event, capturing the joy and
            camaraderie of our Enugu USA community.
          </p>
        </div>
        <div class="galleryImageWrapper">
          <div class="imageHolder holder1">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.24_24d5229a.jpg")}
              alt=""
            />
            <div class="imageInfo">
              <h3>Current and Past Presidents of Enugu USA</h3>
            </div>
          </div>
          <div class="imageHolder holder2">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.07_55290f8b.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
          <div class="imageHolder holder3">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.22_0cec9e6b.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
          <div class="imageHolder holder4">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.12_82780dc9.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
          <div class="imageHolder holder5">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.17_7d90f578.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
          <div class="imageHolder holder6">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.07_61a6cdc3.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
          <div class="imageHolder holder7">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.02_7a6a50ae.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
          <div class="imageHolder holder8">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.24_199a46ae.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
          <div class="imageHolder holder9">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.26_7a0559c0.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
          <div class="imageHolder holder10">
            <img
              src={require("../assets/images/convention/WhatsApp Image 2024-02-14 at 10.45.08_945a9082.jpg")}
              alt=""
            />
            {/* <div class="imageInfo">
              <h3>Award given to marylang Ambassador</h3>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Convention2023;
