import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/ChapterDetails.css";

const HESOChapterDetails = () => {
  return (
    <>
      <Navbar />

      <div className="chapterWrapper">
        <img
          src={require("../assets/images/Chapters/Houston Enugu State Organization (HESO) Chapter/PHOTO-2023-06-28-06-58-29_2.jpg")}
          alt=""
        />
        <h2>
          Brief History of "Houston Enugu State Organization Inc, (HESO TX)"
        </h2>
        <p>
          <span>
            Like everything in Texas "Lone Star State" , HESO ranks top among
            the greatest Nigerian socio-cultural organisations in North America
            with over one hundred registered members. Founded in 1990 by
            cream-de la-cream of Enugu state indigens in Houston metropolis with
            zeal, passion and vision to promote our cultural heritage and
            welfare of all Enugu diasporans in this part of the world.
          </span>
          <span>
            For over three decades, HESO has been adjudged as most stable
            Nigerian socio-cultural organization as exemplified by successive
            transparent elections and leadership that emanated from our
            inaugural President Atty Ozo Ike Nweze to the incumbent president
            -Chief Sir David Ekwueme(DND). Without exaggeration, HESO parades an
            array of professional vibrant and well meaning members who made
            indelible marks in the affairs of Enugu USA national association.
            The likes of Professor Andrew Éwoh, Late Prof. Bedford Nwabueze
            Umez, Atty Celestine C Ude, Chief Sir David Ekwueme, Sir Francis
            Enih, , Chief Maxwell Ene, Sir Patrick Nwachukwu, Prince Sunny
            Nwankwo Ogbodo, Chief Prince Kevin Chime, Hon Chidi Kanife, Chief
            Engr John Nzekwe, Chief DR Vitalis Okechukwu and Late Mr. Fred
            Ofordu among others, had made profound contributions of talent,
            treasure and time in advancing the frontiers of HESO TX and Enugu
            USA National Association,in general. HESO TX is reckoned to have
            organized star studded EUSA national conventions in 2001 and 2014
            respectively; while her members have held and still hold various
            elected and appointed positions where they distinguish themselves
            like true statesmen of EUSA.
          </span>
        </p>
        <p>
          <span>Viva HESO!!!</span>
          <span>Viva Enugu USA!!!.</span>
        </p>
        <p>Chief Sir David Ekwueme ( Current HESO TX President 2024-2026).</p>
      </div>
      <Footer />
    </>
  );
};

export default HESOChapterDetails;
