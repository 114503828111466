import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/Blog.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../utils/url";

const Blog = () => {
  const navigate = useNavigate();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/api/blog/articles`)
      .then((res) => {
        console.log(res);
        setBlog(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function trimText(text) {
    var words = text.split(/\s+/).slice(0, 20);
    return words.join(" ");
  }

  return (
    <>
      <Navbar />

      <section className="blogWrapper">
        <div className="blogheading">
          <h2>Blog</h2>
          <p>
          Explore the Enugu USA blog for a diverse range of articles, updates, and stories celebrating our community's unity and progress.
          </p>
        </div>
        <div className="eventsCardWrapper">
          {blog.map((el) => {
            return (
              <div className="eventsCard">
                <img src={el.image} alt="" />
                <div className="content">
                  <div className="heading">
                    <h3>{el.title}</h3>
                    <p>Category: {el.category_title}</p>
                  </div>
                  <p>{trimText(el.body)}. . .</p>
                  <button onClick={() => navigate(`/blog/${el.slug}`)}>
                    View More
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Blog;
