import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/Blog.css";
import axios from "axios";
import { url } from "../utils/url";

const BlogDetails = () => {
  const [blog, setBlog] = useState([]);
  const windowurl = window.location.href.split("/");
  const slug = windowurl[windowurl.length - 1];

  useEffect(() => {
    console.log(slug);
    axios
      .get(`${url}/api/blog/article/${slug}`)
      .then((res) => {
        console.log(res);
        setBlog(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Navbar />

      <div className="blogDetailsWrapper">
        <img src={blog.image} alt="" />
        <div className="heading">
          <h3>{blog.title}</h3>
          <p>Category: {blog.category_title}</p>
        </div>
        <div className="content">{blog.body}</div>
      </div>

      <Footer />
    </>
  );
};

export default BlogDetails;
