import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa6";
import "../styles/Home.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ESANC from "../assets/images/Chapters/Enugu State Association of North Carolina(ESANC)/Screenshot_20230815-215613_WhatsApp.jpg";
import ESEWAC from "../assets/images/Chapters/Enugu State Elite Women Association of Southern California (ESEWAC)/image.png";
import GLES from "../assets/images/Chapters/Global Ladies of Enugu State Association Washington DC Metropolitan Inc. (GLES)/PHOTO-2023-07-22-10-21-15.jpg";
import OfuobiWomen from "../assets/images/Chapters/Ofuobi Women of Enugu State WDC Inc/PSX_20230814_211347.jpg";
import Umuada from "../assets/images/Chapters/Umuada Wawa USA, Houston Texas/7974e4f7-bed7-456a-855e-0359be537e84.jpg";
import EPLSOUTHERNCALIFORNIA from "../assets/images/Chapters/Enugu Progressive Ladies Association of Southern California/PHOTO-2023-08-16-00-34-33.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { EMAILJS_PUBLIC_KEY, EMAILJS_SERVICE_ID, EMAILJS_SUB_TEMPLATE_ID } from "../utils/secretKeys";
import emailjs from "@emailjs/browser";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { url } from "../utils/url";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const form = useRef();
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs.sendForm(
        EMAILJS_SERVICE_ID,
        EMAILJS_SUB_TEMPLATE_ID,
        form.current,
        EMAILJS_PUBLIC_KEY
    ).then(
        (result) => {
            console.log(result);
            setLoading(false)
            toast.success("Message Sent Successfully");
            form.current.reset();
        },
        (error) => {
            setLoading(false)
            console.log(error);
        }
      ).catch(err => {
        console.log(err)
      })
  };

  useEffect(() => {
    axios
      .get(`${url}/api/blog/articles`)
      .then((res) => {
        console.log(res);
        setBlog(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function trimText(text) {
    var words = text.split(/\s+/).slice(0, 20);
    return words.join(" ");
  }

  return (
    <>
      <Navbar />
      {/* <!-- HERO SECTION, WHY ENUGU USA --> */}
      <div class="heroSection">
        <div class="heroOverlay">
          <div class="leftHero">
            <h1>Why Enugu USA</h1>
            <p>
              Enugu USA stands as a beacon of unity and empowerment for the
              Enugu State diaspora community. With a rich history spanning over
              two decades, our organization is dedicated to fostering
              connections, driving positive change, and enriching the lives of
              our members. To gain deeper insights into our mission, ongoing
              initiatives, and the essence of our organization, we encourage you
              to explore the enlightening words of our esteemed
              President's speech.
            </p>
            <div className="presidentSpeech">
              <img
                src={require("../assets/images/Current Executives/Dr. Mrs. Chy Agbayi.jpg")}
                alt=""
              />
              <div className="presidentContent">
                <h3>2023 Enugu USA Convention President Speech</h3>
                <p class="conventionbrief">
                  I am excited, humbled, and delighted to stand here tonight to
                  welcome everyone. You are here because you care, love,
                  cherish, and want this organization to succeed. We appreciate
                  your effort, time, commitment, and support in every way you
                  have contributed to the success of this event.
                </p>
                <a
                  href={require("../assets/documents/2023 CONVENTION PRESIDENTIAL SPEECH.pdf")}
                  target="_blank"
                >
                  {" "}
                  Click here to read more
                </a>
              </div>
            </div>
          </div>
          <div class="rightHero">
            {/* <!-- <div class="rect"></div> --> */}
            <iframe
              src="https://www.youtube.com/embed/U7zR1nCioQI?si=EA_0RLb0tsRuTqYh"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <NavLink to={"/2023-convention"}>
              Click here to view the full video
              <span class="material-symbols-outlined"> arrow_right_alt </span>
            </NavLink>
          </div>
        </div>
      </div>

      {/* <!-- MEDICAL MISSION SECTION --> */}
      <section class="medicalMissionWrapper">
        <h2>Medical Mission</h2>
        <p>
          Our mission is simple yet profound: to provide vital healthcare
          services to those in need across Enugu State, Nigeria. Led by a
          dedicated team of healthcare professionals and volunteers, our medical
          missions aim to address the healthcare disparities faced by
          underserved communities, offering medical care, screenings, and
          education to improve overall well-being.
        </p>
        <div class="imageWrapper">
          <img
            src={require("../assets/images/medical cases.jpg")}
            alt=""
            class="image1"
          />
          <img
            src={require("../assets/images/2022MedicalMision/PHOTO-2023-12-02-21-50-30_2.jpg")}
            alt=""
            class="image2"
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9376.JPG")}
            alt=""
            class="image3"
          />
          <img
            src={require("../assets/images/2019MedicalMission/IMG_9383.JPG")}
            alt=""
            class="image4"
          />
          <img
            src={require("../assets/images/2019MedicalMission/3241c96b-1f78-4326-ad24-1561e6dd4098.JPG")}
            alt=""
            class="image5"
          />
          <img
            src={require("../assets/images/2022MedicalMision/PHOTO-2023-12-02-21-50-30_3.jpg")}
            alt=""
            class="image6"
          />
        </div>
      </section>

      {/* <!-- MEMEBERSHIP CHAPTERS SECTION --> */}
      <section class="memebershipChapters">
        <div class="heading">
          <h2>Our Membership Chapters</h2>
          <p>
            Enugu USA's membership chapters serve as vibrant community hubs
            across the United States, fostering connections, promoting cultural
            heritage, and spearheading impactful initiatives to support Enugu
            State and its residents. With dedicated members in various cities,
            each chapter plays a crucial role in advancing Enugu USA's mission
            of empowerment, unity, and service, ensuring that the organization's
            reach and impact extend far and wide.
          </p>
        </div>
        <div class="memebershipChaptersCardWrapper">
          <div class="membershipChapterCard">
            <img src={ESANC} alt="" />
            <h3>
              Enugu State Association of North Carolina(ESANC)
              <span>
                The Enugu State Association of North Carolina (ESANC) is a
                vibrant community organization dedicated to fostering unity,
                cultural preservation, and progress among Enugu indigenes
                residing in North Carolina. Through various initiatives, events,
                and programs, ESANC strives to connect Enugu people, celebrate
                their rich heritage, and contribute positively to the local
                community.
              </span>
            </h3>
          </div>
          <div class="membershipChapterCard">
            <img src={EPLSOUTHERNCALIFORNIA} alt="" />
            <h3>
              Enugu Progressive Ladies Association of Southern California.
              <span>
                Empowering women, fostering community connections, and driving
                positive change in Southern California while celebrating Enugu
                State's rich heritage.
              </span>
            </h3>
          </div>
          <div class="membershipChapterCard">
            <img src={GLES} alt="" />
            <h3>
              Global Ladies of Enugu State Association Washington DC
              Metropolitan Inc. (GLES)
              <span>
                Uniting Enugu State natives in the Washington DC Metropolitan
                area to empower, support, and advocate for each other while
                promoting cultural pride and community engagement.
              </span>
            </h3>
          </div>
          <div class="membershipChapterCard">
            <img src={OfuobiWomen} alt="" />
            <h3>
              Ofuobi Women of Enugu State WDC Inc
              <span>
                Uniting Enugu State natives in the Washington DC area with a
                shared purpose, fostering solidarity, and promoting empowerment
                through collective action and support.
              </span>
            </h3>
            .
          </div>
          <div class="membershipChapterCard">
            <img src={Umuada} alt="" className="homeMembershipCard"/>
            <h3>
              Umuada Wawa USA, Houston, Texas
              <span>
                Empowering women, fostering community bonds, and championing
                development initiatives in Houston, Texas, while celebrating the
                rich cultural heritage of Enugu State
              </span>
            </h3>
          </div>
          <div class="membershipChapterCard">
            <img src={ESEWAC} alt="" />
            <h3>
              Enugu State Elite Women Association of Southern California
              (ESEWAC)
              <span>
                Bringing together accomplished women from Enugu State in
                Southern California to empower, inspire, and uplift each other
                while contributing to the community's advancement and cultural
                preservation.
              </span>
            </h3>
          </div>
        </div>
        <button onClick={() => navigate("/membership-chapters")}>
          View More
          <span class="material-symbols-outlined"> arrow_right_alt </span>
        </button>
      </section>

      {/* <!-- DONATION SECTION --> */}
      <section class="donationWrapper">
        <img src={require("../assets/images/collage.jpg")} alt="" />
        <div class="rightDonations">
          <h2>Donations / Subscriptions</h2>
          <p>
            Your generosity fuels our mission to make a positive impact in Enugu
            State and beyond. Every donation contributes directly to vital
            community projects, healthcare initiatives, educational programs,
            and empowerment efforts, ensuring a brighter future for all.
            Together, we can build stronger communities and transform lives.
            Thank you for your support!
          </p>
          <button onClick={() => navigate("/donations")}>Donate</button>
        </div>
      </section>

      {/* <!-- EVENT CONVENTION SECTION --> */}
      {/* <section class="eventsWrapper">
        <div class="heading">
          <h2>Blog</h2>
          <p>
            Explore the Enugu USA blog for a diverse range of articles, updates,
            and stories celebrating our community's unity and progress.
          </p>
        </div>
        <div class="eventsCardWrapper">
          {blog.slice(0, 3).map((el) => {
            return (
              <div class="eventsCard">
                <img src={el.image} alt="" />
                <div class="content">
                  <div class="heading">
                    <p>Category: {el.category_title}</p>
                    <h3>{el.title}</h3>
                  </div>
                  <p>{trimText(el.body)}. . .</p>
                  <button onClick={() => navigate(`/blog/${el.slug}`)}>
                    View More
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <button class="btn" onClick={() => navigate("/blog")}>
          View All
          <span class="material-symbols-outlined"> arrow_right_alt </span>
        </button>{" "}
      </section> */}

      {/* <!-- NEWSLETTER SECTION --> */}
      <section class="newsletterWrapper">
        <div class="newsletterOverlay">
          <h3>Subscribe to our NewsLetter</h3>
          <form action=""  ref={form} onSubmit={sendEmail}>
            <input type="email" name="email" placeholder="Enter Email Address" required />
            <button disabled={loading}>{loading ? 'Loading...' : 'Submit'}</button>
          </form>
          <div class="iconWrapper">
            <FaFacebook />
            <FaWhatsapp />
            <FaInstagram />
          </div>
          <img src="" alt="" />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
