// export const EMAILJS_SERVICE_ID = 'service_0yxgcla'
// export const EMAILJS_SUB_TEMPLATE_ID = 'template_5f4a3n9'
// export const EMAILJS_CONTACT_TEMPLATE_ID = 'template_ue8o1is'
// export const EMAILJS_PUBLIC_KEY = 'thwnkgFpX4aYCRVnP70kj'



export const EMAILJS_SERVICE_ID = 'service_u39l8xm'
export const EMAILJS_SUB_TEMPLATE_ID = 'template_076g2m5'
export const EMAILJS_CONTACT_TEMPLATE_ID = 'template_eiee61w'
export const EMAILJS_PUBLIC_KEY = 'jr36t-QS0AzJyVHzj'