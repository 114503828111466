import Navbar from "../components/Navbar";
import "../styles/Executives.css";
import joychukwu from "../assets/images/Medical Mission Executives/JoyChukwu.jpg"
import CHIDIKANIFE from "../assets/images/Medical Mission Executives/CHIDI KANIFE.jpg"
import Francisca from "../assets/images/Medical Mission Executives/Francisca Enih, Author.jpg"
import Nzekwe from "../assets/images/Medical Mission Executives/JOHN NZEKWE.jpg"
import Lumberton from "../assets/images/Medical Mission Executives/Joseph Lumberton.jpg"
import Martha from "../assets/images/Medical Mission Executives/DrMartha.jpg"
import Footer from "../components/Footer";

const MMExecutives = () => {
  
  return (
    <>
      <Navbar />
      <section className="executives">
        <div className="heading">
          <h2>Our Medical Mission Executives</h2>
          <p style={{ textAlign: "center" }}></p>
        </div>


        <div className="executivesCards">
          <div className="card"
          >
          <img src={Lumberton} alt="" />
              <div class="content">
                <h3>Dr. Joseph Lumberton Okoye-Awgu</h3>
                <p id="position">ENUGU USA MEDICAL MISSION DIRECTOR</p>
              </div>
          </div>
          <div className="card"
          >
          <img src={Nzekwe} alt="" />
              <div class="content">
                <h3>Chief Engr. JOHN NZEKWE</h3>
                <p id="position">ENUGU USA  MEDICAL MISSION MANAGING DIRECTOR </p>
              </div>
          </div>
          <div className="card"
          >
          <img src={joychukwu} alt="" />
              <div class="content">
                <h3>Hon. Mrs. Joy Chy Chukwu</h3>
                <p id="position">Enugu USA Medical Mission Coordinator</p>
              </div>
          </div>
          <div className="card"
          >
          <img src={CHIDIKANIFE} alt="" />
              <div class="content">
                <h3>Hon. CHIDI KANIFE Jr.</h3>
                <p id="position">Special Adviser to the National President on Medical Mission Special Projects.</p>
              </div>
          </div>
          <div className="card"
          >
          <img src={Francisca} alt="" />
              <div class="content">
                <h3>Lady Dr. Francisca Enih</h3>
                <p id="position"> Secretary, EUSA Medical Mission <br/> Author: 15 Minutes a Day Book</p>
              </div>
          </div>
          <div className="card"
          >
          <img src={Martha} alt="" />
              <div class="content">
                <h3>Dr. Martha Ozo, DNP</h3>
                <p id="position"> Enugu USA Medical Mission Director of Nursing </p>
              </div>
          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
};

export default MMExecutives;
