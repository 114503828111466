import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/Executives.css";
import AlexOzomma from "../assets/images/Current Leadership/Chief Alex Ozomma.jpg";
import ChristyObimah from "../assets/images/Current Leadership/Hon. Lady Mrs. Christy Obimah.jpg";
import PascalMba from "../assets/images/Current Leadership/Sir Pascal Mba.jpg";
import SirTony from "../assets/images/Current Leadership/Sir Tony.jpg";
import { useState } from "react";

const CurrentBODLeadership = () => {
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState();
  const [title, setTitle] = useState("");

  const showInfoModal = (name, image, title, desc) => {
    setName(name);
    setTitle(title);
    setModal(true);
    setImage(image);
    document.getElementById("description").innerHTML = desc;
  };

  return (
    <>
      <Navbar />

      <section class="executives">
        <div class="heading">
          <h2>
            Meet Our Current <span>BOD</span> Leadership
          </h2>
          <p>
            Meet our esteemed Board of Directors, a dedicated team of leaders at
            the helm of our organization's strategic direction and vision. With
            a wealth of experience, expertise, and a shared commitment to
            serving the Enugu State diaspora, our Board members exemplify
            integrity, compassion, and innovation.
          </p>
        </div>

        {/* <!-- EXECUTIVE CARDS --> */}
        <div class="executivesCards">
          <div
            class="card"
            onClick={() =>
              showInfoModal(
                `Chief Alex Ozomma`,
                AlexOzomma,
                `Current Board Chairman EUSA`,
                `<p> 

              <span>Double Chief Alex Nnaemeka Ozomma </span> </br>
              <span>Ikuku 1 of Ezeagu Igbudu</span> </br>
              <span>Ifediche 1 of Ezeagu Igbudu</span> 

              </p>
              <p> 

              <span>Married to: Chief Mrs. Chizoba Ozomma </span> </br>
              <span>Blessed with 4 beautiful children.</span> 

              </p>
              <p> 

              <span>From Akama Oghe, Ezeagu LGA, Enugu state, Nigeria. </span>

              </p>
              <p> 

              <span>Emeritus President Enugu Progressive Union, Houston Texas </span> </br>
              <span>Emeritus President EZPA, Houston Texas. </span> 

              </p>
              <p> 

              <span>EX soccer international  </span> </br>
              <span>Former GM & Coach: Houston Green Eagles.  </span> 

              </p>
              <p> 

              <span>Hobbies: Sports, Reading and Community Activities </span> </br>

              </p>
              `
              )
            }
          >
            <img src={AlexOzomma} alt="" />
            <div class="content">
              <h3>Chief Alex Ozomma Ikuku 1 Na Ezeagu Igbudu</h3>
              <p id="position">Current Board Chairman EUSA</p>
            </div>
          </div>
          <div
            class="card"
            // onClick={() => showInfoModal(`Hon. Lady Mrs. Christy Obimah`, ChristyObimah, `Deputy Board Chair`)}
          >
            <img src={ChristyObimah} alt="" />
            <div class="content">
              <h3>Hon. Lady Mrs. Christy Obimah</h3>
              <p id="position">Deputy Board Chair</p>
            </div>
          </div>
          <div
            class="card" 
            // onClick={() => showInfoModal(`Sir Pascal Mba`, PascalMba, `Assistant Board Secretary`)}
          >
            <img src={SirTony} alt="" />
            <div class="content">
              <h3>Sir Tony Ibeh</h3>
              <p id="position">National BOD Secretary</p>
            </div>
          </div>
          <div
            class="card"
            // onClick={() => showInfoModal(`Sir Pascal Mba`, PascalMba, `Assistant Board Secretary`)}
          >
            <img src={PascalMba} alt="" />
            <div class="content">
              <h3>Sir Pascal Mba</h3>
              <p id="position">Assistant BOD Secretary</p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- EXECUTIVES DETAILS OVERLAY --> */}
      <section class={modal ? "executivesDetails" : "hide"}>
        <div class="executivesDetailsWrap">
          <div class="executiveOverlay">
            <p class="close" onClick={() => setModal(false)}>
              x
            </p>
            <img src={image} alt="" />
            <div class="content">
              <div class="head">
                <h3>{name}</h3>
                <p>{title}</p>
              </div>
              <p id="description" class="description"></p>
              <div class="contact">
                <h3>Contact Me</h3>
                <div class="icons">
                  <i class="bi bi-facebook"></i>
                  <i class="bi bi-whatsapp"></i>
                  <i class="bi bi-twitter-x"></i>
                  <i class="bi bi-instagram"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default CurrentBODLeadership;
