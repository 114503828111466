import { NavLink } from "react-router-dom";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <ul class="companyInfo">
          <img src={require("../assets/images/enugulogo.png")} alt="" />
          <p>
            Empowering communities, fostering unity, and making a difference in
            the lives of people both in the USA and Nigeria.
          </p>
        </ul>

        <ul>
          <h3>Useful Links</h3>
          <ul class="footer_menu">
            <NavLink to={"/"}>Home</NavLink>
            <NavLink to={"/about"}>About Us</NavLink>
            <NavLink to={"/donations"}>Donations/Subscriptions </NavLink>
            <NavLink to={"/currentexecutives"}>Executives</NavLink>
            <NavLink to={"/membership-chapters"}>Membership Chapters</NavLink>
          </ul>
        </ul>
        <ul>
          <h3>Company</h3>
          <ul class="footer_menu">
            <NavLink to={"/terms-and-conditions"}>
              Terms and Conditions of Use
            </NavLink>
            <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
          </ul>
        </ul>
        <div class="contact">
          <h3>Contact</h3>
          <ul class="footer_menu">
            {/* <p>
              <i class="bi bi-telephone-fill"></i>+124 03814267
            </p> */}
            <p>
              <i class="bi bi-envelope-fill"></i>info@enuguusa.net
            </p>
            <p>
              <i class="bi bi-geo-alt-fill"></i>Enugu USA Contact 8431 Bates
              drive Bowie Maryland, 20720, U.S.A
            </p>

            <div class="social">
              <h3>Join us on social media</h3>
              <div class="footer_links">
                <i class="bi bi-whatsapp"></i>
                <i class="bi bi-facebook"></i>
                <i class="bi bi-twitter-x"></i>
                <i class="bi bi-instagram"></i>
              </div>
            </div>
          </ul>
        </div>
      </footer>
      <div class="copyright">
        <span> &copy; 2021 Copyright: &nbsp;</span>
        ENUGU USA. powered by ITAMATEKS
      </div>
    </>
  );
};

export default Footer;
