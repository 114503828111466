import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/Terms.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <section class="terms">
        <div class="heading">
          <h2>Privacy Policy</h2>
          <p>
            At Enugu USA, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, disclose, and safeguard your
            information when you visit our website or interact with us.
          </p>
        </div>
        <ul>
          <span>Information We Collect</span>
          <li>
            <span>Personal Information: </span>
            We may collect personal information such as your name, email
            address, postal address, phone number, and other contact details
            when you voluntarily provide it to us through forms, registrations,
            or inquiries.
          </li>
          <li>
            <span>Usage Information: </span>
            We automatically collect certain information about your device and
            usage patterns when you visit our website, including your IP
            address, browser type, operating system, pages viewed, and referring
            website.
          </li>
          <li>
            <span>Cookies: </span>
            We use cookies and similar technologies to enhance your browsing
            experience, analyze website traffic, and personalize content. You
            can adjust your browser settings to disable cookies or opt-out of
            certain tracking technologies.
          </li>
        </ul>
        <ul>
          <span>How We Use Your Information:</span>
          <li>
            We may use your personal information to communicate with you,
            respond to your inquiries, provide services, process transactions,
            and send you updates, newsletters, and promotional materials.
          </li>
          <li>
            We use usage information to analyze trends, administer the website,
            track user movements, and gather demographic information for
            aggregate use.
          </li>
          <li>
            We may use cookies to improve website functionality, personalize
            your experience, and target advertising based on your interests.
          </li>
        </ul>
        <ul>
          <span>Information Sharing and Disclosure:</span>
          <li>
            We do not sell, trade, or rent your personal information to third
            parties without your consent.
          </li>
          <li>
            We may share your information with trusted service providers who
            assist us in operating our website, conducting business, or
            servicing you, as long as those parties agree to keep this
            information confidential.
          </li>
          <li>
            We may disclose your information when required by law or to protect
            our rights, property, or safety, or the rights, property, or safety
            of others.
          </li>
        </ul>
        <ul>
          <span>Data Security: </span>
          <li>
            We implement appropriate security measures to protect against
            unauthorized access, alteration, disclosure, or destruction of your
            personal information.
          </li>
        </ul>
        <ul>
          <span>Children's Privacy:</span>
          <li>
            Our website is not directed to individuals under the age of 13, and
            we do not knowingly collect personal information from children under
            13. If you are a parent or guardian and believe that your child has
            provided us with personal information, please contact us immediately
            so that we can delete such information.
          </li>
        </ul>
        <ul>
          <span>Changes to This Privacy Policy:</span>
          <li>
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes will be effective immediately upon posting on this
            page. We encourage you to review this Privacy Policy periodically
            for any updates.
          </li>
        </ul>
        <ul>
          <span>Contact Us:</span>
          <li>
            If you have any questions or concerns about our Privacy Policy, or
            if you would like to exercise your rights regarding your personal
            information, please contact us at (insert contact information).
          </li>
        </ul>
        <p>
          By using our website, you consent to the terms of this Privacy Policy.
        </p>
      </section>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
