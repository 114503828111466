import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import "../styles/Project.css"


const Project = () => {

    return<>
        <Navbar/>

        <div className="projectWrapper">
            <div className="projectHeading">
                <h2>Enugu USA Building Project</h2>
                <p>At EnuguUSA, we're excited to share the floor plans for our latest building project with you. Take a closer look at our meticulously designed floor plans.</p>
            </div>

        </div>
        <div className="building">
            <img src={require("../assets/images/Project/building.jpg")} alt="" />
            <img src={require("../assets/images/Project/building2.jpg")}  alt="" />
        </div>

        <div className="floorWrapper">
            <div className="leftFloor">
                <h3>Floor Plans</h3>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam animi earum ullam reprehenderit. Reiciendis sed voluptatibus ex praesentium, facere similique!</p>
            </div>

            <div className="rightFloor">
                <div className="floorCard">
                    <img src={require("../assets/images/Project/EUSA GROUND FLOOR PLAN-2_page-0001.jpg")} alt="" onClick={() => window.open(require("../assets/images/Project/EUSA FIRST FLOOR PLAN_page-0001.jpg"), "_blank")}/>
                    <h3>Ground FLoor Plan</h3>
                </div>
                <div className="floorCard">
                    <img src={require("../assets/images/Project/EUSA FIRST FLOOR PLAN_page-0001.jpg")} alt=""  onClick={() => window.open(require("../assets/images/Project/EUSA FIRST FLOOR PLAN_page-0001.jpg"), "_blank")}/>
                    <h3>Upper FLoor Plan</h3>
                </div>
            </div>
        </div>



        <Footer/>

    </>

}

export default Project