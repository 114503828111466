import { useNavigate } from "react-router"
import "../styles/success.css"

const Cancel = () => {

    const navigate = useNavigate()
    return <>
        <section class="cancelWrapper">
            <span class="material-symbols-outlined"> cancel </span>
            <h2>Failed</h2>
            <h3>Payment Cancelled</h3>
            <p>Click button below to go back to the home page</p>
            <button onClick={() => navigate("/")}>Back</button>
        </section>
    </>

}

export default Cancel