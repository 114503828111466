import { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/Executives.css";
import CharlesAgwu from "../assets/images/Chairman Emeritus/Chief Charles Agwu.jpg";
import ChibuzoNweke from "../assets/images/Chairman Emeritus/Chief Engr. Chibuzo Nweke.jpg";
import IzuOnyeani from "../assets/images/Chairman Emeritus/Chief Izu Onyeani.jpg";
import HillaryOnyishi from "../assets/images/Chairman Emeritus/Engr. Hillary Onyishi.jpg";
import JohnChukwuemeka from "../assets/images/Chairman Emeritus/Engr. John. Chukwuemeka. Agbo.jpg";
import MOEne from "../assets/images/Chairman Emeritus/M.O. ENE.jpg";
import BlessingChukwu from "../assets/images/Chairman Emeritus/Princess Blessing Chukwu.jpg";

const PastBODChiarman = () => {
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState(
    "../assets/images/Call center-cuate 1.png"
  );
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const showInfoModal = (name, image, title, desc) => {
    setName(name);
    setDesc(desc);
    setTitle(title);
    setModal(true);
    setImage(image);
    document.getElementById("description").innerHTML = desc;
  };

  return (
    <>
      <Navbar />

      <section class="executives">
        <div class="heading">
          <h2>
            Meet Our Past <span>BOD</span> Chiarman Emeritus
          </h2>
          <p>
            Meet our esteemed past Board Chairmen, whose guidance and dedication
            have shaped the trajectory of our organization over the years. Each
            former chairman has left an indelible mark on Enugu USA,
            contributing their unique vision, expertise, and passion to our
            collective mission. Their steadfast commitment to unity,
            empowerment, and community development continues to inspire us as we
            carry forward their legacy.
          </p>
        </div>

        {/* EXECUTIVE CARDS  */}
        <div class="executivesCards">
          <div
            class="card"
            //  onClick="showInfoModal(`M.O. ENE, PhD Chair`, `assets/images/Chairman Emeritus/M.O. ENE.jpg`, `BOD CHAIRMAN EMERITUS (1999-2002)`)"
          >
            <img src={MOEne} alt="" />
            <div class="content">
              <h3>M.O. ENE, PhD Chair</h3>
              <p id="position">BOD CHAIRMAN EMERITUS (1999-2002)</p>
            </div>
          </div>
          <div
            class="card"
            //  onClick="showInfoModal(`Chief Charles Agwu`, `/assets/images/Chairman Emeritus/Chief Charles Agwu.jpg`, `BOD CHAIRMAN EMERITUS (2002-2004)`)"
          >
            <img src={CharlesAgwu} alt="" />
            <div class="content">
              <h3>Chief Charles Agwu</h3>
              <p id="position">BOD CHAIRMAN EMERITUS (2002-2004)</p>
            </div>
          </div>
          <div
            class="card"
            onClick={() =>
              showInfoModal(
                `Chief Engr. Chibuzo Nweke, Okibe n'Obe`,
                `/assets/images/Chairman Emeritus/Chief Engr. Chibuzo Nweke.jpg`,
                `BOD CHAIRMAN EMERITUS (2010-2012)`
              )
            }
          >
            <img src={ChibuzoNweke} alt="" />
            <div class="content">
              <h3>Chief Engr. Chibuzo Nweke, Okibe n'Obe</h3>
              <p id="position">BOD CHAIRMAN EMERITUS (2010-2012)</p>
            </div>
          </div>
          <div
            class="card"
            onClick={() =>
              showInfoModal(
                `Princess Blessing Chukwu (Ezenwanyi)`,
                `assets/images/Chairman Emeritus/Princess Blessing Chukwu.jpg`,
                `BOD CHAIRLADY Emeritus(2012-2014)`,
                `Princess Blessing Chukwu (Ezenwanyi) EnuguUSA BOD Chairman Emeritus  and First Elected Female BOD Chair 2012 - 2014, EnuguUSA BOD Secretary Emeritus and First Elected Female BOD Secretary 2010-2012,  President Emeritus EnuguUSA Arizona Chapter 2009-2016.`
              )
            }
          >
            <img src={BlessingChukwu} alt="" />
            <div class="content">
              <h3>Princess Blessing Chukwu (Ezenwanyi)</h3>
              <p id="position">BOD CHAIRLADY Emeritus (2012-2014)</p>
            </div>
          </div>
          <div
            class="card"
            //  onClick="showInfoModal(`Elder. Engr. John. Chukwuemeka. Agbo  ( Egogboogu 1 of Enugu Ezike)`, `/assets/images/Chairman Emeritus/Engr. John. Chukwuemeka. Agbo.jpg`, `BOD CHAIRMAN EMERITUS (2014-2016)`)"
          >
            <img src={JohnChukwuemeka} alt="" />
            <div class="content">
              <h3>
                Elder. Engr. John. Chukwuemeka. Agbo ( Egogboogu 1 of Enugu
                Ezike)
              </h3>
              <p id="position">BOD CHAIRMAN EMERITUS (2014-2016)</p>
            </div>
          </div>
          <div
            class="card"
            //  onClick="showInfoModal(`Chief Izu Onyeani Akamonyeeze Vll n'Ọzara`, `assets/images/Chairman Emeritus/Chief Izu Onyeani.jpg`, `BOD CHAIRMAN EMERITUS (2016-2018)`)"
          >
            <img src={IzuOnyeani} alt="" />
            <div class="content">
              <h3>Chief Izu Onyeani Akamonyeeze Vll n'Ọzara</h3>
              <p id="position">BOD CHAIRMAN EMERITUS (2016-2018)</p>
            </div>
          </div>
          <div
            class="card"
            //  onClick="showInfoModal(`Engr. Hillary Onyishi`, `/assets/images/Chairman Emeritus/Engr. Hillary Onyishi.jpg`, `BOD CHAIRMAN EMERITUS (2018-2022)`)"
          >
            <img src={HillaryOnyishi} alt="" />
            <div class="content">
              <h3>Engr. Hillary Onyishi, Ph.D</h3>
              <p id="position">BOD CHAIRMAN EMERITUS (2018-2022)</p>
            </div>
          </div>
        </div>
      </section>

      <section class={modal ? "executivesDetails" : "hide"}>
        <div class="executivesDetailsWrap">
          <div class="executiveOverlay">
            <p class="close" onClick={() => setModal(false)}>
              x
            </p>
            <img src={image} alt="" />
            <div class="content">
              <div class="head">
                <h3>{name}</h3>
                <p>{title}</p>
              </div>
              <p id="description" class="description"></p>
              <div class="contact">
                <h3>Contact Me</h3>
                <div class="icons">
                  <i class="bi bi-facebook"></i>
                  <i class="bi bi-whatsapp"></i>
                  <i class="bi bi-twitter-x"></i>
                  <i class="bi bi-instagram"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PastBODChiarman;
