import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/About";
import CurrentExutives from "./pages/CurrentExecutives";
import CurrentBODLeadership from "./pages/CurrentBODLeadership";
import PastBODChiarman from "./pages/PastBODChiarman";
import PresidentEmemritus from "./pages/PresdentEmeritus";
import ContactUs from "./pages/ContactUs";
import Convention2023 from "./pages/2023-convention";
import MedicalMission2019 from "./pages/2019MedicalMission";
import MedicalMission2022 from "./pages/2022MedicalMission";
import MembershipChapters from "./pages/MembershipChapters";
import Donations from "./pages/Donations";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Success from "./pages/success";
import Cancel from "./pages/cancel";
import EducationMission from "./pages/EducationMission";
import Youth from "./pages/Youth";
import MedicalMission2023 from "./pages/2023MedicalMission";
import ChapterDetails from "./pages/ChapterDetails";
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import HESOChapterDetails from "./pages/HESOChapterDetails";
import MMExecutives from "./pages/MMExecutives";
import Project from "./pages/Project";

function App() {
  return (
    <>
      <Router>
      <ToastContainer/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/currentexecutives" element={<CurrentExutives />} />
          <Route path="/pastbodleadership" element={<PastBODChiarman />} />
          <Route path="/presidentemeritus" element={<PresidentEmemritus />} />
          <Route path="/project" element={<Project />} />
          <Route path="/medical-mission-executives" element={<MMExecutives />} />
          <Route
            path="/currentbodleadership"
            element={<CurrentBODLeadership />}
          />
          <Route path="/2023-convention" element={<Convention2023 />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route
            path="/2023-Medical-Mission"
            element={<MedicalMission2023 />}
          />
          <Route
            path="/2022-Medical-Mission"
            element={<MedicalMission2022 />}
          />
          <Route
            path="/2019-Medical-Mission"
            element={<MedicalMission2019 />}
          />
          <Route path="/membership-chapters" element={<MembershipChapters />} />
          <Route path="/membership-chapters/HESO" element={<HESOChapterDetails />} />
          <Route path="/donations" element={<Donations />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/Education-Mission" element={<EducationMission />} />
          <Route path="/Our-Youths" element={<Youth />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
